<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Pre-Lab Assignment 4</h2>

      <p class="mb-n3">
        This quiz is intended to prepare you to perform the experiment. Therefore, you must earn
        <b>AT LEAST 80%</b> of the marks for the quiz BEFORE arriving to complete the experiment or
        you will not be admitted into the lab.
      </p>

      <v-radio-group v-model="inputs.requirements" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRequirements"
          :key="'pt-0-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        a) In this experiment, why is it essential to wear safety glasses? Choose the best answer.
      </p>

      <v-radio-group v-model="inputs.inputA" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsA"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) In this experiment, you will determine the concentration of an unknown diprotic acid
        using your standardized base. What is the best representation of the balanced chemical
        equation for this reaction?
      </p>

      <v-radio-group v-model="inputs.inputB" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsB"
          :key="'pt-2-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Why is it essential that we standardize the base solution as soon as it is prepared?
        Select the best answer of those provided.
      </p>

      <v-radio-group v-model="inputs.inputC" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsC"
          :key="'pt-3-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) When preparing the base solution, why do we add the base to the water and not the other
        way around? Select all valid reasons.
      </p>

      <p v-for="option in optionsD" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputD" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-6">
        e) When performing titrations, which of the options below would be considered good
        technique? Select all correct options.
      </p>

      <p v-for="option in optionsE" :key="'pt-5-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputE" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaPrelab4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        requirements: null,
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: [],
        inputE: [],
      },
      optionsRequirements: [
        {
          text: 'I understand and agree to the quiz requirement',
          value: 'agree',
        },
        {text: 'I do not agree to the quiz requirement', value: 'disagree'},
      ],
      optionsA: [
        {
          text: 'You are working with concentrated bases',
          value: 'concentratedBases',
        },
        {text: 'You are working with acids', value: 'acids'},
        {
          text: 'You are working in the lab and it is always required that you wear safety glasses in lab',
          value: 'alwaysWearThem',
        },
      ],
      optionsB: [
        {
          text: 'Diprotic acid + base yields salt and water',
          value: 'wordForm',
        },
        {
          text: '$\\ce{H2A(aq) + NaOH(aq) -> NaA(aq) + 2 H2O(l)}$',
          value: '1equivBase',
        },
        {
          text: '$\\ce{H2A(aq) + 2 NaOH(aq) -> Na2A(aq) + 2 H2O(l)}$',
          value: '2equivBase',
        },
        {
          text: '$\\ce{HA(aq) + NaOH(aq) -> NaA(aq) + H2O(l)}$',
          value: '1to1mono',
        },
      ],
      optionsC: [
        {text: 'There is not much time in the lab', value: 'noTime'},
        {
          text: 'The base solution is not stable over a long period of time',
          value: 'unstable',
        },
        {
          text: 'It is the most important value measured',
          value: 'importantValue',
        },
      ],
      optionsD: [
        {
          text: 'The base is corrosive and slippery',
          value: 'corrosiveSlippery',
        },
        {text: 'The base is more dense than water', value: 'moreDense'},
        {text: 'The volume of the base is smaller', value: 'lessVolume'},
        {
          text: 'We are more likely to produce a homogeneous solution',
          value: 'moreHomogeneous',
        },
        {
          text: 'The base generates heat on mixing which can be dispersed as we mix',
          value: 'heat',
        },
      ],
      optionsE: [
        {
          text: 'Adding the indicator at the beginning',
          value: 'indicatorAtStart',
        },
        {
          text: 'Talking to your partner while adding base',
          value: 'talkToFriend',
        },
        {
          text: 'Performing multiple trials to ensure the final concentration determined is accurate to within 2%',
          value: 'multipleTrials',
        },
        {
          text: 'Carefully noting the volume of base added',
          value: 'carefulMeasurementVolume',
        },
        {
          text: 'Adding the base more rapidly at first, then slowing the addition as we approach the endpoint',
          value: 'fastThenSlow',
        },
        {
          text: 'Waiting 30 s after the first colour change to ensure that the endpoint has been reached',
          value: 'wait30secAfterFirstColorChange',
        },
        {text: 'Stirring the solution constantly', value: 'alwaysStir'},
        {
          text: 'Waiting a few minutes after adding each drop of base as you get closer to the endpoint',
          value: 'waitFewMinAfterEachDrop',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
